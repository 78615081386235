/**
 * Links for the NSUK and FULAFIA project
*/

export default {
  // api: "https://test.nsuk.edu.ng/api",
  // api: "https://ug.nsuk.edu.ng/api"
 api: 'http://localhost:8080/api',
  //  api:  "https://pg.nsuk.edu.ng/api",


  /**
   * @description - links for the Lafia
   */
  //api: "https://test.fulafia.edu.ng/api",
 // api: 'https://my.fulafia.edu.ng/api'
};

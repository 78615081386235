import Roles from "./Roles";


/**
 * Navigation Links for Staff roles in the system
 */
const OfficerLinks = {
  management: [
    {
      title: "Students",
      icon: "mdi-account-group",
      url: "students",
      roles: [Roles.DepartmentOfficer, Roles.Hod, Roles.AcademicSecretary],
    },
    {
      title: "Lecturers",
      icon: "mdi-account-supervisor-circle",
      url: "lecturers",
      roles: [Roles.Hod],
    },
    {
      title: "Results",
      icon: "mdi-book-arrow-left-outline",
      url: "results",
      roles: [Roles.Lecturer],
    },
    {
      title: "Courses",
      icon: "mdi-google-circles-group",
      url: "courses",
      roles: [Roles.DAP, Roles.Hod],
    },
    // {
    //   title: "Student progress status",
    //   icon: "mdi-google-circles-group",
    //   url: "student_progress_status",
    //   roles: [Roles.Hod],
    // },
    {
      title: "Student (Security)",
      icon: "mdi-account-group",
      url: "mystudent",
      roles: [Roles.SecurityOfficer],
    },
    {
      title: "Students Management",
      icon: "mdi-account-group-outline",
      url: "student_management",
      roles: [Roles.StudentManagement, Roles.StudentManagementAdministrative],
    },
    {
      title: "Applicants(Support)",
      icon: "mdi-account-group",
      url: "application-support",
      roles: [Roles.SupportOfficer, Roles.StudentManagement, Roles.StudentManagementAdministrative],
    },
    {
      title: "Prerequisite Courses",
      icon: "mdi-backburger",
      url: "prerequisites",
      roles: [Roles.Hod],
    },
    {
      title: "Department Management",
      icon: "mdi-archive-cog",
      url: "department_settings",
      roles: [Roles.Hod],
    },
    {
      title: "My Courses",
      icon: "mdi-bank",
      url: "my_courses",
      roles: [Roles.Lecturer],
      academic: true,
    },
    {
      title: "Results - Department",
      icon: "mdi-chevron-triple-down",
      url: "manage_results",
      roles: [Roles.Hod, Roles.DepartmentOfficer],
    },
    {
      title: "Results - Faculty",
      icon: "mdi-chevron-triple-right",
      url: "manage_results_faculty",
      roles: [Roles.FacultyExamBoard, Roles.FacultyOfficer, Roles.Dean],
    },
    {
      title: "Results - Sub Senate",
      icon: "mdi-chevron-triple-left",
      url: "manage_results_subsenate",
      roles: [Roles.SenateSubCommittee, Roles.DVCAcademics],
    },
    {
      title: "Results - Senate",
      icon: "mdi-chevron-triple-up",
      url: "manage_results_senate",
      roles: [Roles.HeadOfSenate, Roles.Senate],
    },
    {
      title: "Admission",
      icon: "mdi-collage",
      url: "admission",
      roles: [
        Roles.AdmissionOfficer,
        Roles.Clearance,
        Roles.ScreeningVerificationOfficer,
      ],
    },
    {
      title: "Change Of Applicant",
      icon: "mdi-school-outline",
      url: "change_programme_applicants",
      roles: [Roles.ChangedOfProgrammeApplicant],
    },
    {
      title: "Change Of Programme",
      icon: "mdi-school-outline",
      url: "change_programme_students",
      roles: [Roles.Hod],
    },
    {
      title: "Hostel",
      icon: "mdi-home-city",
      url: "hostel",
      roles: [Roles.HostelOfficer],
    },
    {
      title: "Student ID Cards",
      icon: "mdi-chevron-triple-up",
      url: "student_id_card",
      roles: [Roles.StudentAffair],
    },
    {
      title: "Data Correction",
      icon: "mdi-chevron-triple-up",
      url: "data_correction",
      roles: [Roles.DataCorrectionVerification],
    },
    {
      title: "Fees",
      icon: "mdi-currency-ngn",
      url: "school_fees",
      roles: [Roles.Bursar, Roles.StudentAccount, Roles.PaymentVerificationOfficer, Roles.StudentManagementAdministrative],
    },
    {
      title: "Biometrics",
      icon: "mdi-fingerprint",
      url: "biometrics",
      roles: [Roles.BiometricOfficer],
    },
      {title: 'Result Mgmt', icon: 'mdi-book-arrow-right-outline', url: 'result_mgmt', roles: [Roles.ResultAdmin]},
    {
      title: "Announcement",
      icon: "mdi-speaker",
      url: "announcement",
      roles: [Roles.AnnouncementOfficer],
    },
    {
      title: "Transcript",
      icon: 'mdi-account',
      url: "transcript_officer",
      roles: [Roles.TranscriptOfficer]
    },
      {
        title: "Statistics",
        icon: "mdi-tune-vertical",
        url: "stats",
        roles: [Roles.StatsOfficer],
      },
  ],

  settings: [
    { title: "Settings", icon: "mdi-cog", url: "settings" },
  ],
};



/**
 * Navigation Links for Admin
 */
const AdminLinks = {
    management: [
        // { title: 'Students', icon: 'mdi-account-group', url: 'students'},
        {title: 'Staff', icon: 'mdi-account-supervisor-circle', url: 'staff'},
        {title: 'Sessions', icon: 'mdi-power-off', url: 'sessions'},
        {title: 'Programmes Types', icon: 'mdi-google-circles-group', url: 'programmes_types'},
        {title: 'Faculties', icon: 'mdi-bank', url: 'faculties'},
        {title: 'Departments', icon: 'mdi-home', url: 'departments'},
        {title: 'Programmes', icon: 'mdi-chevron-triple-up', url: 'programmes'},
        {title: 'Schools', icon: 'mdi-bank', url: 'schools'},
        {title: 'Faculty Deans', icon: 'mdi-account-star', url: 'faculty_deans'},
        {title: 'Head of Departments', icon: 'mdi-account-circle', url: 'head_of_departments'},
        {title: 'Manage Senates', icon: 'mdi-briefcase-account', url: 'manage_senates'},
        {title: 'Manage Students', icon: 'mdi-account-group', url: 'manage_students'},
        {title: 'Fees', icon: 'mdi-currency-ngn', url: 'school_fees'},
        {title: 'Approval', icon: 'mdi-google-circles-group', url: 'approval'},
        {title: 'Audit Trail', icon: 'mdi-account-group', url: 'audit'},
        {title: 'Documents', icon: 'mdi-account-group', url: 'documents'},
        {title: 'Portal Documents', icon: 'mdi-account', url: 'portal_documents'}
    ],

    settings: [
        {title: 'Configurations (System)', icon: 'mdi-cog', url: 'system_settings'},
        {title: 'Configurations (Misc)', icon: 'mdi-cog-outline', url: 'configuration_misc'},
        {title: 'Nationality', icon: 'mdi-flag', url: 'nationality'},
        {title: 'Profile Settings', icon: 'mdi-shield-account', url: 'settings'},
        {title: "Approval", icon: 'mdi-google-circles-group', url: 'approval'},
    ]
}


/**
 * Navigation Links for student
 */
const StudentLinks = {
    management: [
        { title: 'Fees', icon: 'mdi-currency-ngn', url: 'fees', cleared: true, mustBePermanent: false },
        { title: 'Bio Data', icon: 'mdi-database', url: 'biodata', cleared: true,  mustBePermanent: false },
        { title: 'Other payment', icon: 'mdi-currency-ngn', url: 'other_fees', cleared: true, mustBePermanent: false },
        { title: 'Course Registration', icon: 'mdi-alpha-c-circle', url: 'course-registration', cleared: false, mustBePermanent: true},
        { title: 'Results', icon: 'mdi-application-edit', url: 'results', cleared: false, mustBePermanent: true },
        { title: 'Accommodation', icon: 'mdi-home', url: 'accommodation', cleared: false, mustBePermanent: true },
        { title: 'Change Programme', icon: 'mdi-domain', url: 'programme', cleared: false, mustBePermanent: true },
        { title: 'My Documents', icon: 'mdi-file-multiple', url: 'student_document', cleared: false, mustBePermanent: true },
        
    ],

    settings: [
        { title: 'Profile Settings', icon: 'mdi-shield-account', url: 'settings', cleared: false, mustBePermanent: false},
    ]
}

export default {
    OfficerLinks: OfficerLinks,
    AdminLinks: AdminLinks,
    StudentLinks: StudentLinks
};

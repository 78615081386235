import axios from "axios";
import Links from "../constants/Links";



/**
 *  This is the Student API hook, for handling student (C) 2021-2023
 */



const Students = {
  /**
   * to make a student permanent
   * @param {object} data
   * @returns {Promise<object>}
   */
  create: async (data) => {
    return axios.post(Links.permanentStudents.create, data);
  },

  /**
   * to make a student temporary
   * @param {object} data
   * @returns {Promise<object>}
   */
  createTemp: async (data) => {
    return axios.post(Links.temporalStudents.create, data);
  },


  /**
   * handles updating of temporary students
   * @param {string} id user id of the student to be updated
   * @param {object} data info to be updated
   * @returns {Promise<object>}
   */
  updateTemp: async (id, data) => {
    return axios.post(Links.temporalStudents.update + id, data);
  },

  /**
   * handles temporary upload of students base on selected session
   * @param {object} data blob file to be uploaded(.xlsx)
   * @param {object} params
   * @returns {Promise<Blob>}
   */
  uploadTemp: async (data, params) => {
    return axios.post(
      Links.temporalStudents.upload + "session/" + params.session,
      data,
      {
        headers: { "Content-Type": "multipart/form-data" },
        timeout: 1000 * 60 * 5,
      }
    );
  },

  /**
   * handles permanent students to be upload
   * @param {object} data blob file to be uploaded(.xlsx)
   * @returns {Promise<Blob>}
   */
  uploadPerm: async (data) => {
    return axios.post(Links.permanentStudents.upload, data, {
      headers: { "Content-Type": "multipart/form-data" },
      timeout: 1000 * 60 * 5,
    });
  },

  /**
   *
   * @param {string} departmentId
   * @param {string} level
   * @param {string} page
   * @param {string} itemsPerPage
   * @returns {Promise<any[]>}
   */
  allPermanentFor: async (departmentId, level, page, itemsPerPage) => {
    return axios.get(
      Links.permanentStudents.all +
        departmentId +
        "?levelId=" +
        level +
        "&page=" +
        page +
        "&size=" +
        itemsPerPage
    );
  },

  /**
   * fetch all students depending on the selected level, faculty, department, programme, country, state, lga, gender,
   * @param {string} reqParams
   * @param {string} page
   * @param {string} itemsPerPage
   * @returns {Promise<any[]>}
   */
  allStudents: async (reqParams, page, itemsPerPage) => {
    let params = "?session=" + reqParams.session;
    if (reqParams.level) {
      params = params + "&level=" + reqParams.level;
    }
    if (reqParams.faculty) {
      params = params + "&faculty=" + reqParams.faculty;
    }
    if (reqParams.department) {
      params = params + "&department=" + reqParams.department;
    }
    if (reqParams.programme) {
      params = params + "&programme=" + reqParams.programme;
    }
    if (reqParams.country) {
      params = params + "&country=" + reqParams.country;
    }
    if (reqParams.state) {
      params = params + "&state=" + reqParams.state;
    }
    if (reqParams.lga) {
      params = params + "&lga=" + reqParams.lga;
    }
    if (reqParams.gender) {
      params = params + "&gender=" + reqParams.gender;
    }
    return axios.get(
      Links.permanentStudents.allStudents +
        params +
        "&page=" +
        page +
        "&size=" +
        itemsPerPage
    );
  },

  /**
   * fetch all students in a given session, level, and programme
   * @returns {Promise<any[]>}
   */
  allStudentsAt: async (session, level,programme) => {
    let params = "?session=" + session;
    params = params + "&level=" + level;
    params = params + "&programme=" + programme;
    return axios.get(
        Links.permanentStudents.allStudentsAt + params
    );
  },

  /**
   * download record sheet for student base on the selected session, level, faculty, department, programme, country, state, lga, gender, paid
   * @param {string} reqParams
   * @returns {Promise<Blob>}
   */
  downloadRecordSheet: async (reqParams) => {
    let params = "?session=" + reqParams.session;
    if (reqParams.level) {
      params = params + "&level=" + reqParams.level;
    }
    if (reqParams.faculty) {
      params = params + "&faculty=" + reqParams.faculty;
    }
    if (reqParams.department) {
      params = params + "&department=" + reqParams.department;
    }
    if (reqParams.programme) {
      params = params + "&programme=" + reqParams.programme;
    }
    if (reqParams.country) {
      params = params + "&country=" + reqParams.country;
    }
    if (reqParams.state) {
      params = params + "&state=" + reqParams.state;
    }
    if (reqParams.lga) {
      params = params + "&lga=" + reqParams.lga;
    }
    if (reqParams.gender) {
      params = params + "&gender=" + reqParams.gender;
    }

    params = params + "&paid=" + reqParams.paid;

    return axios.get(Links.permanentStudents.downloadReport + params, {
      responseType: "blob",
    });
  },

  /**
   *
   * @param {string} query search query  (userid or institutional email)
   * @param {string} programmeTypeId programmeTypeId
   * @returns {Promise<object>} the query database on the query specified
   */
  search: async (query, programmeTypeId) => {
    return axios.get(
      Links.permanentStudents.search +
        "?q=" +
        query +
        "&programme_type=" +
        programmeTypeId
    );
  },

  /**
   * search for a student
   * @param { string} query student id
   * @returns {Promise<object>} the query database on the query specified
   */
  find: async (query) => {
    return axios.get(Links.permanentStudents.find + "?q=" + query);
  },

  /**
   * Migrate a student  from permanent to become temporary
   * @param {string} id userId
   * @returns {Promise<object>}
   */
  migrateSingle: async (id) => {
    return axios.put(Links.temporalStudents.migrateSingle + id);
  },

  /**
   * Migrate all student depending on the selected department, session and programmeType
   * @param {string} dep department id
   * @param {string} session session id
   * @param {string} programmeType programmeType id
   * @returns {Promise<object>}
   */
  migrateAll: async (dep, session, programmeType) => {
    return axios.put(
      Links.temporalStudents.migrateAllDep +
        dep +
        "/" +
        session +
        "/" +
        programmeType
    );
  }, // not available

  /**
   * offer admission to a student
   * @param {object} data student info
   * @returns {Promise<object>}
   */
  offerAdmission: async (data) => {
    return axios.post(Links.temporalStudents.offerAdmission, data);
  },

  /**
   * register courses for a student
   * @param {object} data student info
   * @returns {Promise<object>}
   */
  registerCourse: async (data) => {
    return axios.post(Links.permanentStudents.registerCourse, data);
  },

  /**
   * unregister courses for a student
   * @param {object} data student info
   * @returns {Promise<object>}
   */
  unRegisterCourse: async (data) => {
    return axios.post(Links.permanentStudents.unRegisterCourse, data);
  },

  /**
   * fetch all registered courses for a student depending on the selected session and semester
   * @param {string} student student id
   * @param {string} session session id
   * @param {string} semester semester id
   * @returns
   */
  getRegisteredCoursesForSemester: async (student, session, semester) => {
    return axios.get(
      Links.permanentStudents.getRegisterCourses +
        "?student=" +
        student +
        "&session=" +
        session +
        "&semester=" +
        semester
    );
  },

  /**
   * suspend a student
   * @param {object} data student info
   * @returns {Promise<object>}
   */
  suspend: async (data) => {
    return axios.post(Links.permanentStudents.suspend, data);
  },

  /**
   * @description extend student deferment
   * @param {string} sessionid pass the sessionid
   * @param {object} data  - the data contains studentId and reason
   * @returns {Promise<object>}
   */
  extend: async (data, session) => {
    return axios.post(Links.permanentStudents.extend + session, data);
  },

  /**
   * cancel student  suspension
   * @param {string} suspendId suspended id
   * @returns {Promise<object>}
   */
  cancelSuspension: async (suspendId) => {
    return axios.put(Links.permanentStudents.cancelSuspension + suspendId);
  },

  /**
   * @description to cancel deferment id
   * @param {string} defermentId - the defermentid to be passed
   * @param {string} sessionId - the sessionId to be passed
   * @returns {Promise<object>}
   */
  cancelDeferment: async (deferId, sessionId) => {
    return axios.put(
      Links.permanentStudents.cancelDeferment + "/" + deferId + "/" + sessionId
    );
  },

  /**
   * fetch all deferred student (paginated)
   * @param {number} page e.g 0
   * @param {number} size e.g 10
   * @returns {Promise<object>}
   */
  allDeferredStudent: async (page, size) => {
    return axios.get(
      Links.permanentStudents.allDeferredStudent +
        "?page=" +
        page +
        "&size=" +
        size
    );
  },

  /**
   * return axios.get(Links.lecturers.search + "?query=" + query)
   */
  /**
   * search for deferred student using the following
   * @param {string} query userId(matric) or student name
   * @returns {Promise<object>} result
   */
  searchForDeferredStudent: async (query) => {
    return axios.get(Links.permanentStudents.searchDeferStudent + "?query=" + query);
  },


  /**
   * fetch all suspended and extended students
   * @param {string} programmeType programmetype id
   * @param {string} departmentId department id
   * @returns {Promise<object>}
   */
  suspendedAndExtended: async (programmeType, departmentId) => {
    return axios.get(
      Links.permanentStudents.suspendedAndExtended +
        programmeType +
        "/department/" +
        departmentId
    );
  },

  /**
   * fetch suspended and extended students
   * @param {string} programmeType programmetype
   * @returns {Promise<object>}
   */
  suspendedAndExtendedAll: async (programmeType) => {
    return axios.get(
      Links.permanentStudents.suspendedAndExtended + programmeType
    );
  },

  /**
   * fetch programmetype and level
   * @param {string} programmeId programmetype id
   * @param {string} levelId level id
   * @param {string} page e.g 0
   * @param {string} size e.g 10
   * @returns {Promise<object>}
   */
  fetchByProgrammeAndLevel: async (programmeId, levelId, page, size) => {
    return axios.get(
      Links.permanentStudents.fetchByProgrammeAndLevel +
        programmeId +
        "/" +
        levelId +
        "?page=" +
        page +
        "&size=" +
        size
    );
  },

  /**
   * update student level
   * @param {string} data level to be updated
   * @returns {Promise<object>}
   */
  upgradeLevel: async (data) => {
    return axios.post(Links.permanentStudents.upgradeLevel, data);
  },

  /**
   * fetch stats for a specific programme
   * @param {string} programme programme id
   * @param {string} level level id
   * @returns {Promise<object>}
   */
  statsForProgramme: async (programme, level) => {
    return axios.get(
      Links.permanentStudents.statsForProgramme +
        "?programme=" +
        programme +
        "&level=" +
        level
    );
  },

  /**
   * search using a biometic status
   * @param {string} query student user id or name
   * @param {string} programmeType programme type id
   * @returns {Promise<object>}
   */
  searchWithBiometricStatus: async (query, programmeType) => {
    return axios.get(
      Links.permanentStudents.searchWithBiometricStatus +
        "?q=" +
        query +
        "&programme_type=" +
        programmeType
    );
  },

  /**
   * step down the level of the student
   * @param {string} userId student userid
   * @param {boolean} maintainPromotion
   * @returns {Promise<object>}
   */
  downgradeLevel: async (userId, maintainPromotion) => {
    return axios.put(Links.permanentStudents.downgradeLevel, {
      id: userId,
      maintainPromotion,
    });
  },

  /**
   * update mode of entry
   * @param {object} data information to be updated
   * @returns {Promise<object>}
   */
  updateModeOfEntry: async (data) => {
    return axios.post(Links.permanentStudents.updateModeOfEntry, data)
  },

  /**
   * enter matric number to be generated for the user
   * @param {string} matric userid as matric number
   * @returns {Promise<object>}
   */
  generateMatricNumber: async (matric) => {
    return axios.put(Links.permanentStudents.generateMatric, {
      id: matric,
    });
  },

  /**
   * update programme of the student
   * @param {string} req programme to be updated
   * @returns {Promise<object>}
   */
  updateProgramme: async (req) => {
    return axios.post(Links.permanentStudents.updateProgramme, req);
  },

  /**
   * Get the stats of the app
   * @param {string} session id
   * No of student, rusticated etc
   * @returns {Promise<object>}
  */
  stats: async (session) => {
    return axios.get(
      Links.permanentStudents.stats +
      "?session=" +
      session
    )
  },

  /**
   * Fixes issues with students registration for a semester
   * @param matric
   * @param semester
   * @returns {Promise<void>}
   */
  addressRegistration: async (matric, semester) => {
    return axios.post(Links.permanentStudents.addressRegistration, {
      student: matric,
      semester: semester
    })
  },

  /**
   *@description upload students' statuses
   * @param data {FormData} form data
   * @returns {Promise<AxiosResponse<any>>}
   */
  uploadStatuses: async(data) => {
    return axios.post(
        Links.permanentStudents.uploadStatuses,
        data,
        {
          headers: { "Content-Type": "multipart/form-data" }
        }
    );
  }
};

export default Students
